import { getQueryStringParams, GetSessionStorage, showError } from '@fuse/utils';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'src/components/Header';
import Loader from 'src/scenes/ApplicationForm/components/Loader';
import { esewaSuccess } from 'src/services/paymentGateway';

export default function PaymentSuccess() {
  const [invoiceDetails, setInvoiceDetails] = useState({
    amount: 0,
    paymentGateway: '',
    transactionId: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const queries = getQueryStringParams(window.location.search);

  useEffect(() => {
    (async () => {
      if (queries.refId || queries.oid) {
        setIsLoading(true);

        const requestBody = {
          oid: queries.oid,
          amt: queries.amt,
          refId: queries.refId,
        };
        const [err, res] = await esewaSuccess(requestBody);
        if (res) {
          setInvoiceDetails({
            ...invoiceDetails,
            amount: res?.amount,
            paymentGateway: res?.paymentGateway,
            transactionId: queries.oid,
          });
        } else if (err) {
          showError(err);
        }
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.refId || queries.oid]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover,user-scalable=0"
        />
      </Helmet>
      <Header />
      <div className="esewa">
        <div className="payment">
          <div className="modal-dialog">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="modal-content">
                <div className="header  d-flex justify-content-center">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="bold-x">Payment Successful</h5>
                      <div className="mb-3 faded-text">
                        Your payment has been successfully done.
                        <br />
                        Check your invoice below.
                      </div>
                    </div>
                    <div className="icon col-12">
                      <img alt="circle-icon" src="/images/circle-check.svg" />
                    </div>
                  </div>
                </div>
                <div className="payment-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Amount Paid</th>
                        <td>Rs. {invoiceDetails.amount}</td>
                      </tr>
                      <tr>
                        <th scope="row">Payment Option</th>
                        <td>{invoiceDetails.paymentGateway || 'ESEWA'}</td>
                      </tr>
                      <tr>
                        <th scope="row">Transaction ID</th>
                        <td>{invoiceDetails.transactionId}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="footer d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-green"
                    onClick={e => {
                      e.preventDefault();
                      window.location.replace(
                        GetSessionStorage('prePaymentUrl') ? GetSessionStorage('prePaymentUrl') + '&payment=done' : '',
                      );
                    }}
                  >
                    Submit Application
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
