import { getQueryStringParams } from '@fuse/utils';
import { AxiosRequestConfig } from 'axios';
// import { activeEnv } from '../../config';
// import staticTemplate from '../../data/trinity_bachelors_bbm_.json';
import {
  asyncFunc,
  asyncFunctionRaw,
  asyncPromiseFunc,
  RawReturnType,
  unAuthorizedFunctionRaw,
  unAuthorizedPromiseFunc,
  unAuthorizedRequest,
} from '../../utils/asyncFunctionUtils';
import { AdmissionDetails, AdmissionFormTemplateJson } from './types';

export interface AdmissionFormParams {
  admissionFormId: string;
}
const BASE = '/v2/admission';
export const getAdmissionForm = async ({ admissionFormId }: AdmissionFormParams) => {
  const [err, response] = await asyncFunc<AdmissionFormTemplateJson>({
    method: 'GET',
    url: `${BASE}/admission-forms/${admissionFormId}`,
  });
  // if (response && activeEnv === 'local') {
  //   console.log(staticTemplate);
  //   return [err, { ...response, templateJson: staticTemplate }];
  // }
  if (response && response.templateJson) {
    try {
      return [err, { ...response, templateJson: JSON.parse(response.templateJson) }];
    } catch (parseError) {
      return [parseError, null];
    }
  }
  return [err, response];
};

export interface FilledApplication {
  id: string;
}

export const getFilledApplication = async ({ id }: FilledApplication) => {
  const [err, response] = await asyncFunctionRaw<AdmissionDetails>({
    method: 'GET',
    url: `${BASE}/submitted-admission-forms/${id}`,
  });
  if (response && response.responseJson) {
    try {
      return [err, { ...response, responseJson: JSON.parse(response.responseJson) }] as RawReturnType<AdmissionDetails>;
    } catch (parseError) {
      return [{ status: 500, message: parseError.message }, null] as RawReturnType<AdmissionDetails>;
    }
  }
  return [err, response] as RawReturnType<AdmissionDetails>;
};

export interface UploadFileParams {
  file: File;
}
export const uploadFile = async ({ file }: UploadFileParams) => {
  const data = new FormData();
  data.append('file', file);
  return await asyncFunc<{ file: string }>({
    method: 'POST',
    url: `${BASE}/upload/files`,
    data,
  });
};

export interface UpdateApplication {
  [id: string]: any;
}
export const updateApplicationAPI = async ({ responseJson, ...rest }: AdmissionDetails) => {
  const [err, response] = await asyncFunc<AdmissionDetails>({
    method: 'POST',
    url: `${BASE}/admission-forms`,
    data: { ...rest, responseJson: JSON.stringify(responseJson) },
  });
  if (response && response.responseJson) {
    try {
      return [err, { ...response, responseJson: JSON.parse(response.responseJson) }];
    } catch (parseError) {
      return [parseError, null];
    }
  }
  return [err, response];
};

export interface SignUpData {
  fullName: string;
  mobileNumber: number;
  programId: string;
  programName: string;
  previousSchool: string;
  email: string;
  password: string;
  admissionFormId: string;
  schoolId: string;
  gpa: string;
}
export interface Applicant extends SignUpData {
  id: string;
  formNo: number;
}
export const signUpAPI = async (data: SignUpData) => {
  return await asyncFunc<Applicant>({
    method: 'POST',
    url: `${BASE}/registration`,
    data,
  });
};

// Generated by https://quicktype.io

export interface LoginDetails extends LoginBase {
  schoolId: string;
}

export interface LoginBase {
  formNo: string | number | undefined;
  password?: string;
}

export const loginAPI = async (data: { email: string; password: string }) => {
  return await asyncFunc<Applicant>({
    method: 'POST',
    url: `${BASE}/login`,
    data,
  });
};

export interface CustomizationParams {
  schoolId: string;
}

export interface DynamicStyle {
  primaryColor: string;
  secondaryColor: string;
  bgImageUrl: string;
}

export interface Customization {
  prefix: string;
  schoolId: string;
  logo: string;
  landingLogo: string;
  schoolName: string;
  appTitle: string;
  icon: string;
  degrees: IDegrees[];
  style?: DynamicStyle;
  title?: string;
  subContent?: string[];
  programOffered?: string[];
  contactDetail?: any;
}

export interface AvailableDegreesType {
  [id: string]: IDegrees;
}
export interface IDegrees {
  name: string;
  label: string;
  logo: string;
}

export const getCustomizationAPI = async () => {
  const params = getQueryStringParams(window.location.search);
  const level = params['level'] || 'bachelor';

  return await unAuthorizedRequest<Customization>({
    method: 'GET',
    url: level ? `${BASE}/signupinfo?level=${level}` : `${BASE}/signupinfo`,
  });
};

export interface Programs {
  admissionFormId: string;
  programName: string;
  degreeName: string;
  programId: string;
  eligibilityThreshold: number;
  applicationCloseDate: number;
  formSubmitted?: boolean;
}
export interface AvailablePrograms {
  programs: Programs[];
}
export interface IAvailableProgramParams {
  level: string;
}
export interface IScholarshipApplicationData {
  admissionFormId: string;
  programId: string;
  links?: string[];
}
export const getAvailableProgramsAPI = async (params: IAvailableProgramParams) => {
  return await unAuthorizedRequest<AvailablePrograms>({
    method: 'GET',
    url: `${BASE}/signupinfo/additional-info`,
    params,
  });
};
export const getAdmitCard = async (submittedFormId: string) => {
  return await asyncPromiseFunc({
    method: 'GET',
    url: `${BASE}/${submittedFormId}/admit-card`,
    responseType: 'blob',
  });
};

export interface IResources {
  name: string;
  type: string;
  fileUrl?: string;
}
export interface IResourcesList {
  id: string;
  resources: IResources[];
  title: string;
}
export const getDownlaodResourcesList = async (level: string) => {
  return await unAuthorizedRequest<IResourcesList[]>({
    method: 'GET',
    url: `${BASE}/resources`,
    params: { level },
  });
};

export const downloadResoucesFile = (resourcesId: string, fileName: string, type: string) => {
  return {
    method: 'GET',
    url: `${BASE}/resources/${resourcesId}/download/${fileName}/${type}`,
  } as AxiosRequestConfig;
};

export interface SubmittedApplications {
  submittedForms: SubmittedForm[];
}

export interface SubmittedForm {
  id: string;
  admissionFormId: string;
  applicantId: string;
  redirectToAdmitCard: boolean;
  stream: string;
  submitted: boolean;
  shift: string;
  applicationCloseDate: number;
}

export const getApplicationList = async () => {
  return await asyncFunc<SubmittedApplications>({
    method: 'GET',
    url: `/v2/admission/submitted-admission-forms`,
  });
};

export const registerUser = async (userInfo: any) => {
  return unAuthorizedRequest({
    method: 'POST',
    url: `${BASE}/registration`,
    data: userInfo,
  });
};

export const passwordResetEmailVerfication = async (prefix: string, email: string) => {
  return await unAuthorizedRequest({
    method: 'POST',
    url: `${BASE}/password-reset`,
    data: { prefix, email },
  });
};

export const passwordReset = async (resetCode: string, newPassword: string) => {
  return await unAuthorizedRequest({
    method: 'POST',
    url: `${BASE}/password-reset/update`,
    data: { resetCode, newPassword },
  });
};

export const registerTrinityPlusTwoUser = async (userInfo: any) => {
  return unAuthorizedRequest({
    method: 'POST',
    url: `${BASE}/trinity/registration`,
    data: userInfo,
  });
};

export const getTrinityPlusTwoAdmissionForm = async (admissionFormId: string, cognitoEmail: string) => {
  const [err, response] = await unAuthorizedRequest<AdmissionFormTemplateJson>({
    method: 'GET',
    url: `${BASE}/trinity/admission-forms/${admissionFormId}`,
    params: { email: cognitoEmail },
  });
  // if (response && activeEnv === 'local') {
  //   console.log(staticTemplate);
  //   return [err, { ...response, templateJson: staticTemplate }];
  // }
  if (response && response.templateJson) {
    try {
      return [err, { ...response, templateJson: JSON.parse(response.templateJson) }];
    } catch (parseError) {
      return [parseError, null];
    }
  }
  return [err, response];
};

export const uploadTrinityPlusTwoFile = async ({ file }: UploadFileParams, cognitoEmail: string) => {
  const data = new FormData();
  data.append('file', file);
  data.append('email', cognitoEmail);
  return await unAuthorizedRequest<{ file: string }>({
    method: 'POST',
    url: `${BASE}/trinity/upload/files`,
    data,
  });
};

export const updateTrinityPlusTwoApplicationAPI = async ({ responseJson, ...rest }: AdmissionDetails) => {
  const [err, response] = await unAuthorizedRequest<AdmissionDetails>({
    method: 'POST',
    url: `${BASE}/trinity/admission-forms`,
    data: { ...rest, responseJson: JSON.stringify(responseJson) },
  });
  if (response && response.responseJson) {
    try {
      return [err, { ...response, responseJson: JSON.parse(response.responseJson) }];
    } catch (parseError) {
      return [parseError, null];
    }
  }
  return [err, response];
};

export const getTrinityPlusTwoFilledApplication = async ({ id }: FilledApplication) => {
  const [err, response] = await unAuthorizedFunctionRaw<AdmissionDetails>({
    method: 'GET',
    url: `${BASE}/trinity/submitted-admission-forms/${id}`,
  });
  if (response && response.responseJson) {
    try {
      return [err, { ...response, responseJson: JSON.parse(response.responseJson) }] as RawReturnType<AdmissionDetails>;
    } catch (parseError) {
      return [{ status: 500, message: parseError.message }, null] as RawReturnType<AdmissionDetails>;
    }
  }
  return [err, response] as RawReturnType<AdmissionDetails>;
};

export const getTrinityPlusTwoAdmitCard = async (submittedFormId: string) => {
  return await unAuthorizedPromiseFunc({
    method: 'GET',
    url: `${BASE}/trinity/${submittedFormId}/admit-card`,
    responseType: 'blob',
  });
};

export const getSubmittedProgramsAPI = async () => {
  return await asyncFunc<AvailablePrograms>({
    method: 'GET',
    url: `${BASE}/submitted/programs`,
  });
};

export const submitScholarshipApplication = async (data: IScholarshipApplicationData) => {
  const [err, response] = await asyncFunc<AdmissionDetails>({
    method: 'POST',
    url: `${BASE}/scholarship`,
    data,
  });
  return [err, response];
};

export const getAvailableProgramsAPIWithAuthorization = async (params: IAvailableProgramParams) => {
  return await asyncFunc<AvailablePrograms>({
    method: 'GET',
    url: `${BASE}/signupinfo/additional-info`,
    params,
  });
};
