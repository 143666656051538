import { CognitoAuth } from '@fuse/config/dist/Cognito';
import { FButton } from '@fuse/ui-components';
import { clearAllStorage, getQueryParams } from '@fuse/utils';
import { useRouter } from '@fuse/utils/dist/useRouter';
import React, { useState } from 'react';
import { useStyle } from 'src/hooks/useStyle';
import { useApplication } from '../../hooks/useApplication';
import { RouteUrl } from '../../router';
import { COUNSELLING_TIME, LINK, SCHOOL, ZOOM_LINK } from './constants';

const Header: React.FC = () => {
  const { push, query } = useRouter<{ level: string }>();
  const { customization, className, availableDegrees, selectedDegrees } = useApplication();

  const { level } = query;

  let collegeLogo = customization?.logo;
  const prefix = customization?.prefix;
  const contactDetail = customization?.contactDetail;
  const { primaryColor, btnPrimaryWithBorder, linkPrimary } = useStyle();
  const [toggleHeaderRight, setToggleHeaderRight] = useState<boolean>(false);

  if (availableDegrees && Object.keys(availableDegrees).length) {
    const currentDegree = availableDegrees[selectedDegrees || level];
    if (currentDegree) {
      collegeLogo = currentDegree.logo;
    }
  }
  const onLogout = async () => {
    await CognitoAuth.signOut();
    clearAllStorage();
    level?.length ? push(`${RouteUrl.LOGIN}?level=${level}&`) : push(RouteUrl.LOGIN);
  };

  return customization?.prefix ? (
    <div className="header-main">
      <div className="logo--wrapper">
        {customization?.prefix &&
        (customization.prefix === 'dev1' || customization.prefix === 'trinity') &&
        selectedDegrees === 'plusTwo' ? (
          <img src={collegeLogo} alt="logo" onClick={() => push('')} />
        ) : (
          <img
            src={collegeLogo}
            alt="logo"
            onClick={() => push(`${RouteUrl.APPLICATION_LIST}?${getQueryParams({ level })}`)}
          />
        )}
      </div>
      <div
        className="icon-hamburger-menu  icn-2x header--menu"
        id="header-toggle"
        aria-label="header--right"
        onClick={() => setToggleHeaderRight(!toggleHeaderRight)}
      />
      <div className={toggleHeaderRight ? 'header--right-open' : 'header--right'}>
        {prefix && typeof prefix === 'string' && prefix.includes('marga') ? (
          <div className="contact mr-4" style={{ color: primaryColor }}>
            <div className="m-0">Contact Details</div>
            <div className="content">{contactDetail?.contactEmail}</div>
          </div>
        ) : (
          <div className="header-zoom">
            {contactDetail?.contactNumber || contactDetail?.contactEmail || contactDetail?.contactName ? (
              <div className="contact-email mr-4" style={{ color: primaryColor }}>
                <div>
                  <span className="content-title ">Contact Details</span>
                </div>
                {prefix && typeof prefix === 'string' && prefix === 'thames' ? (
                  <>
                    <div className="content">
                      <span>
                        <span className="mr-1">{contactDetail?.contactName}</span>
                        <span className="">{contactDetail?.contactNumber}</span>
                      </span>
                    </div>
                    <div className="content content-appoint">
                      <span className="font-weight-semibold">Book your appointment</span>
                      <span className="">{contactDetail?.contactEmail}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="content">
                      <span>
                        <span className="mr-1">{contactDetail?.contactName}</span>
                        <span className="">{contactDetail?.contactNumber}</span>
                      </span>
                      <span className="">{contactDetail?.contactEmail}</span>
                    </div>
                  </>
                )}
              </div>
            ) : undefined}
            <div>
              {prefix && typeof prefix === 'string' && prefix !== 'thames' ? (
                contactDetail?.links?.[0]?.link ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={contactDetail?.links?.[0]?.link}
                    className="zoom-conselor text-red mr-4"
                    style={linkPrimary ?? undefined}
                  >
                    <span className="zoom-logo mr-2">
                      <img
                        alt=""
                        src={
                          contactDetail?.links?.[0]?.type
                            ? contactDetail?.links?.[0]?.type === LINK.zoom.type
                              ? LINK.zoom.logo
                              : contactDetail?.links?.[0]?.type === LINK.meet.type
                              ? LINK.meet.logo
                              : contactDetail?.links?.[0]?.type === LINK.skype.type
                              ? LINK.skype.logo
                              : ''
                            : ''
                        }
                      />
                    </span>
                    <div className="label">
                      <div className="time">
                        {prefix && typeof prefix === 'string' && prefix.includes(SCHOOL.marga)
                          ? COUNSELLING_TIME.marga
                          : COUNSELLING_TIME.default}
                      </div>
                      <div>Live Online Counseling</div>
                    </div>
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={className === SCHOOL.boston ? ZOOM_LINK.boston : ZOOM_LINK.trinity}
                    className="zoom-conselor text-red mr-4"
                    style={linkPrimary ?? undefined}
                  >
                    <span className="zoom-logo mr-2">
                      <img alt="" src="/images/zoom-logo.png" />
                    </span>
                    <div className="label">
                      <div className="time">
                        {prefix && typeof prefix === 'string' && prefix.includes('marga')
                          ? COUNSELLING_TIME.marga
                          : COUNSELLING_TIME.default}
                      </div>
                      <div>Live Online Counseling</div>
                    </div>
                  </a>
                )
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        {customization?.prefix &&
        (customization.prefix === 'dev1' || customization.prefix === 'trinity') &&
        selectedDegrees === 'plusTwo' ? (
          ''
        ) : (
          <div className="header--button">
            <FButton
              label="Logout"
              className="btn btn-primary"
              onClick={() => onLogout()}
              style={btnPrimaryWithBorder ?? undefined}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Header;
