import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { GetStorageComponent } from './storage';

export const asyncFunc = <T = any>(request: AxiosRequestConfig) => {
  const accessToken = GetStorageComponent('access_token');
  const idToken = GetStorageComponent('id_token');
  request.headers = {
    Authorization: 'bearer ' + accessToken,
    idToken: idToken,
  };

  return axios
    .request<T>(request)
    .then((res: any) => {
      return res.data ? [null, res.data as T] : [res.data, null];
    })
    .catch((err: any) => {
      return err && err.data && err.data.error_description
        ? [err.data.error_description, null]
        : err && err.data && err.data.message
        ? [err.data.message]
        : err && err.data && err.data.error
        ? [err.data.error, null]
        : [err, null];
    });
};

export const asyncPromiseFunc = (request: AxiosRequestConfig) => {
  const accessToken = GetStorageComponent('access_token');
  const idToken = GetStorageComponent('id_token');
  if (accessToken) {
    request.headers = {
      Authorization: 'bearer ' + accessToken,
      idToken: idToken,
    };
  }
  return axios.request(request);
};

export const asyncPromiseDataFunc = (request: AxiosRequestConfig) => {
  const accessToken = GetStorageComponent('access_token');
  const idToken = GetStorageComponent('id_token');
  if (accessToken) {
    request.headers = {
      Authorization: 'bearer ' + accessToken,
      idToken: idToken,
    };
  }
  return axios
    .request(request)
    .then((res: any) => {
      return res.data;
    })
    .catch((err: any) => {
      const error =
        err && err.data && err.data.message
          ? err.data.message
          : err && err.data && err.data.error
          ? err.data.error
          : err;
      return Promise.reject(error);
    });
};

export const unAuthorizedRequest = <T = any>(request: AxiosRequestConfig) => {
  return axios
    .request<T>(request)
    .then((res: any) => {
      return res.data ? [null, res.data as T] : [res.data, null];
    })
    .catch((err: any) => {
      return err && err.data && err.data.error_description
        ? [err.data.error_description, null]
        : err && err.data && err.data.message
        ? [err.data.message]
        : err && err.data && err.data.error
        ? [err.data.error, null]
        : [err, null];
    });
};

export type RawReturnType<T> = [{ status: number; message: string } | null, T | null];
export const asyncFunctionRaw = async <T = {}>(request: AxiosRequestConfig): Promise<RawReturnType<T>> => {
  const accessToken = GetStorageComponent('access_token');
  const idToken = GetStorageComponent('id_token');
  request.headers = {
    Authorization: 'bearer ' + accessToken,
    idToken: idToken,
  };
  try {
    const response = await axios.request<T>(request);
    return [null, response.data];
  } catch (err) {
    return [
      {
        status: err.status,
        message: err.data.message,
      },
      null,
    ];
  }
};

export const unAuthorizedFunctionRaw = async <T = {}>(request: AxiosRequestConfig): Promise<RawReturnType<T>> => {
  try {
    const response = await axios.request<T>(request);
    return [null, response.data];
  } catch (err) {
    return [
      {
        status: err.status,
        message: err.data.message,
      },
      null,
    ];
  }
};

export const unAuthorizedPromiseFunc = (request: AxiosRequestConfig) => {
  return axios.request(request);
};
