import CSS from 'csstype';
import { useApplication } from '../useApplication';

export const useStyle = () => {
  const { customization } = useApplication();

  const { style } = customization || {};

  const primaryColor = style?.primaryColor;

  const secondaryColor = style?.secondaryColor;

  const btnPrimary: CSS.Properties | undefined = primaryColor
    ? {
        background: primaryColor,
        textTransform: 'uppercase',
        color: 'white',
      }
    : undefined;

  const btnPrimaryWithBorder: CSS.Properties | undefined = primaryColor
    ? {
        background: primaryColor,
        textTransform: 'uppercase',
        color: 'white',
        borderColor: primaryColor,
      }
    : undefined;

  const linkPrimary: CSS.Properties | undefined = primaryColor
    ? {
        color: primaryColor,
      }
    : undefined;
  const bgSecondary: CSS.Properties | undefined = primaryColor
    ? {
        backgroundColor: secondaryColor,
      }
    : undefined;
  const bgPrimary: CSS.Properties | undefined = primaryColor
    ? {
        backgroundColor: primaryColor,
      }
    : undefined;

  return {
    btnPrimary,
    linkPrimary,
    bgPrimary,
    bgSecondary,
    primaryColor,
    secondaryColor,
    btnPrimaryWithBorder,
  };
};
