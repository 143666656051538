import { CognitoAuth } from '@fuse/config/dist/Cognito';
import { getQueryStringParams, removeCognitoSession, SetStorageComponent } from '@fuse/utils';
import axios from 'axios';
import config from '../config';
// import CrossStorage from 'cross-storage';
/**
 * [baseURL address to the server api (URL) for production]
 * @type {String}
 */

const baseURL = config.rest_url; // dev server

axios.defaults.baseURL = baseURL;
// axios.defaults.timeout = 15000
const queryString = getQueryStringParams(window.location.search);
const { userEmail } = queryString;

axios.interceptors.response.use(
  response => {
    return response;
  },
  (error: any) => {
    if (error) {
      if (error.response) {
        if (error.response.status === 401 && (!userEmail || !userEmail?.length)) {
          return retryRequest(error);
        }
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(null);
  },
);

/**
 *
 * @param error retry request by getting new access_token with refresh_token
 */
const retryRequest = (error: any) => {
  //check unauthorized user
  return CognitoAuth.currentSession()
    .then((session: any) => {
      const originalRequest = error.config;
      originalRequest._retry = true;
      originalRequest.headers = {
        ...originalRequest.headers,
        ...{
          Authorization: 'bearer ' + session.accessToken.jwtToken,
          idToken: session.idToken.jwtToken,
        },
      };

      SetStorageComponent('id_token', session.idToken.jwtToken);
      SetStorageComponent('access_token', session.accessToken.jwtToken);
      return axios(originalRequest);
    })
    .catch(retryError => {
      console.log('RetryError:: ', retryError);
      if (window.location) {
        const queryString = getQueryStringParams(window.location.search);
        if (queryString && queryString.error !== 'session_expired') {
          // add redirect url for amplify to redirect after login
          const url = window.location.search
            ? `${window.location.pathname}${window.location.search}`
            : `${window.location.pathname}`;
          window.location.href = `/?error=session_expired&redirect_url=${encodeURIComponent(url)}`;
        }
      } else {
        removeCognitoSession();
        CognitoAuth.signOut();
      }
    });
};

export const rootURL = baseURL;
export default axios;
