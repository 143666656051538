// import { showNotification } from "../utils/notification"

import { unAuthorizedRequest } from 'src/utils/asyncFunctionUtils';

interface TransactionRefIdParams {
  formSubmissionId: any;
  transactionAmount: number;
  paymentGateway: any;
  timeZone: string;
}
export const getTransactionRefId = async (data: TransactionRefIdParams) => {
  const [err, response] = await unAuthorizedRequest({
    method: 'POST',
    url: '/api/v2/transactions/order',
    data: data,
  });
  return [err, response];
};

export const khaltiRedirectUrl = async (refId: string) => {
  const [err, response] = await unAuthorizedRequest({
    method: 'GET',
    url: `/api/v2/khalti/paymentUrl?refId=${refId}`,
  });
  if (err) {
    console.log(err);
  }
  return response;
};

export const khaltiSuccess = async (data: any) => {
  const [err, response] = await unAuthorizedRequest({
    method: 'POST',
    url: '/api/v2/khalti/saveTransaction',
    data: data,
  });
  return [err, response];
};

export const esewaSuccess = async (data: any) => {
  const [err, response] = await unAuthorizedRequest({
    method: 'POST',
    url: '/api/v2/esewa/saveTransaction',
    data: data,
  });
  return [err, response];
};
