import { useMemo } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { getQueryStringParams } from '../../utils/urlParse';

export const useRouter = <T = {}>() => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...getQueryStringParams(location.search),
        ...params,
      } as T,
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};
