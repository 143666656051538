export { showSuccess, showError } from '@fuse/utils/dist/alertNotification';
/* import Noty from 'noty';

export const showSuccess = (message: string) => {
  return new Noty({
    type: 'success',
    timeout: 5000,
    theme: 'bootstrap-v4',
    layout: 'topCenter',
    text: message,
    progressBar: false,
    closeWith: ['button'],
  }).show();
};

export const showError = (message: string, duplicate: boolean = true) => {
  const notyfy = new Noty({
    type: 'error',
    timeout: 5000,
    theme: 'bootstrap-v4',
    layout: 'topCenter',
    text: message,
    progressBar: false,
    closeWith: ['button'],
  });

  if (duplicate) {
    notyfy.show();
  } else if (!duplicate && !document.getElementById('noty_layout__topCenter')) {
    notyfy.show();
  }
  return notyfy;
};
 */
