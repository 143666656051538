// let storageType = sessionStorage;
const storageType = localStorage || window.localStorage;

export function SetStorageType(rememberMe: boolean) {
  /* if (!rememberMe) {
        storageType = sessionStorage;
    } else {
        storageType = localStorage;
    } */
}

export function SetSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function GetSessionStorage(key: string) {
  return sessionStorage.getItem(key);
}

export default function SetStorageComponent(key: string, value: string) {
  storageType.setItem(key, value);
}

export function GetStorageComponent(key: string) {
  return storageType.getItem(key);
}

export function DeleteStorageComponent(key: string) {
  return storageType.removeItem(key);
}

export function SetJsonStorageComponent(key: string, value: string) {
  storageType.setItem(key, JSON.stringify(value));
}

export function clearStorageComponent() {
  storageType.clear();
}

export function getUserStorageComponent(key: string) {
  const userInfo = storageType.getItem(key);
  if (userInfo !== null) {
    const item = JSON.parse(userInfo);
    if (!item['coursesEnrolled']) {
      item['coursesEnrolled'] = [];
    }
    return item;
  }
  return null;
}
