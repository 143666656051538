import { GetSessionStorage } from '@fuse/utils';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { activeEnv } from './config';
import PaymentSuccess from './scenes/AdmissionInfo/Trinity/Plus2/Signup/Esewa/PaymentSuccess';
import Loader from './scenes/ApplicationForm/components/Loader';
import KhaltiPaymentSuccess from './scenes/AdmissionInfo/Trinity/Plus2/Signup/KhaltiPayment/KhaltiPaymentSuccess';

const ApplicationFormTemplate = React.lazy(() => import('./scenes/Design/Application'));
const ApplicationFormSignUp = React.lazy(() => import('./scenes/Design/Signup'));
const Success = React.lazy(() => import('./scenes/Design/Signup/Success'));
const ApplicationFormInfo = React.lazy(() => import('./scenes/Design/Forum'));
const AdmissionCard = React.lazy(() => import('./scenes/Design/Forum/admissionCard'));
const MainRoutes = React.lazy(() => import('./MainRoutes'));
const Landing = React.lazy(() => import('./scenes/Design/Landing'));
const LandingTemplate = React.lazy(() => import('./scenes/Design/Landing/TrinityLandingTemplate'));
const BachelorTemplate = React.lazy(() => import('./scenes/Design/Landing/BachelorTemplate'));
const MyApplication = React.lazy(() => import('./scenes/Design/Application/MyApplication'));
const ApplicationList = React.lazy(() => import('./scenes/Design/StandardForm/ApplicationList'));
const StandardForm = React.lazy(() => import('./scenes/Design/StandardForm/Forum/index'));

export enum RouteUrl {
  HOME = '/',
  SIGNUP = '/signup',
  LOGIN = '/login',
  APPLY = '/apply',
  SIGNUP_SUCCESS = '/success',
  ADMISSION_CARD = '/admission-card',
  APPLICATION_FORM_SUCCESS = '/submitted',
  APPLICATION_LIST = '/applications',
  FORGET_PASSWORD = '/forget-password',
}
const MainRouter: React.FC = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Switch>
        {activeEnv !== 'production' && <Route path={'/template'} exact component={ApplicationFormTemplate} />}
        {activeEnv !== 'production' && <Route path={'/template/signup'} exact component={ApplicationFormSignUp} />}
        {activeEnv !== 'production' && <Route path={'/template/email'} exact component={Success} />}
        {activeEnv !== 'production' && <Route path={'/template/forum'} exact component={ApplicationFormInfo} />}
        {activeEnv !== 'production' && <Route path={'/template/card'} exact component={AdmissionCard} />}
        {activeEnv !== 'production' && <Route path={'/template/myapplication'} exact component={MyApplication} />}
        {activeEnv !== 'production' && <Route path={'/template/application-list'} exact component={ApplicationList} />}
        {activeEnv !== 'production' && <Route path={'/template/standard-form'} exact component={StandardForm} />}

        {/* FOR Landing pages */}
        {activeEnv !== 'production' && <Route path={'/template/index'} exact component={Landing} />}
        {activeEnv !== 'production' && <Route path={'/template/trinity'} exact component={LandingTemplate} />}
        {activeEnv !== 'production' && <Route path={'/template/bachelor'} exact component={BachelorTemplate} />}
        <Route path={'/trinity/paymentSuccess'} render={() => <PaymentSuccess />} />
        <Route path={'/trinity/khalti/paymentSuccess'} render={() => <KhaltiPaymentSuccess />} />
        <Route
          path={'/trinity/paymentFailure'}
          component={() => <>{window.location.replace(GetSessionStorage('prePaymentUrl') || '')}</>}
        />
        <Route path={RouteUrl.HOME} component={MainRoutes} />
      </Switch>
    </React.Suspense>
  );
};
export default MainRouter;
