export const activeEnv = process.env.REACT_APP_API_ENV || process.env.NODE_ENV || 'development';

const config = {
  rest_url: process.env.REACT_APP_REST_URL,
  redirect_url: process.env.REACT_APP_REDIRECT_URL_CLASSROOM,
  title: process.env.REACT_APP_TITLE,
  account_url: process.env.REACT_APP_ACCOUNT_URL,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS_CLASSROOM,
  facebookPixel: process.env.REACT_APP_FACEBOOK_PIXEL,
  websiteDashboard: process.env.REACT_APP_WEBSITE_DASHBOARD_URL,
  classroomRedirect_url: process.env.REACT_APP_CLASSROOM_URL,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  cognitoProjectRegion: process.env.REACT_APP_COGNITO_PROJECT_REGION,
  cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
  cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoRedirectUrl: process.env.REACT_APP_COGNITO_CLASSROOM_REDIRECT_URL,
  sentry_url: process.env.REACT_APP_SENTRY_URL,
  package_version: process.env.REACT_APP_VERSION,
};

if (
  !process.env.REACT_APP_REDIRECT_URL_CLASSROOM &&
  !process.env.REACT_APP_REST_URL &&
  !process.env.REACT_APP_TITLE &&
  !process.env.REACT_APP_ACCOUNT_URL &&
  !process.env.REACT_APP_WEBSITE_DASHBOARD_URL &&
  !process.env.REACT_APP_CLASSROOM_URL &&
  !process.env.REACT_APP_GOOGLE_ANALYTICS_CLASSROOM &&
  !process.env.REACT_APP_FACEBOOK_PIXEL &&
  !process.env.REACT_APP_FIREBASE_API_KEY &&
  !process.env.REACT_APP_FIREBASE_PROJECT_ID &&
  !process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID &&
  !process.env.REACT_APP_FIREBASE_APP_ID &&
  !process.env.REACT_APP_SENTRY_URL
) {
  throw new Error(`Cannot find .env.${activeEnv} file or environment values are missing`);
}

export default config;
