export const SCHOOL = {
  boston: 'boston',
  marga: 'marga',
  trinity: 'trinity',
};
export const ZOOM_LINK = {
  boston: 'https://zoom.us/j/95372674274?pwd=ZFFwclJHbGxDRFlpWnZDcG9LMWRsZz09',
  trinity: 'https://zoom.us/j/95238033836 ',
};
export const LINK = {
  zoom: { type: 'zoom', logo: '/images/zoom-logo.png' },
  meet: { type: 'meet', logo: '/images/meet-logo.png' },
  skype: { type: 'skype', logo: '/images/skype-logo.png' },
};
export const COUNSELLING_TIME = {
  marga: '9:00am to 4pm',
  default: '8:00 AM to 5:00 PM',
};
