// Hook (use-auth.js)
import { normalizeData } from '@fuse/utils';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { SelectOptionTypes } from '../../components/Fuse/FSelect';
import { RouteUrl } from '../../router';
import {
  AvailableDegreesType,
  Customization,
  getAdmissionForm,
  getCustomizationAPI,
  getFilledApplication,
  getTrinityPlusTwoAdmissionForm,
  getTrinityPlusTwoFilledApplication,
  loginAPI,
  LoginBase,
  signUpAPI,
  SignUpData,
  SubmittedForm,
  updateApplicationAPI,
  updateTrinityPlusTwoApplicationAPI,
} from '../../services/application';
import { FormJSON } from '../../services/application/types';
import { showError } from '../../utils/alertNotification';
import { getQueryString } from '../../utils/urlParse';
import { useRouter } from '../router/useRouter';

export type CurrentTab = string;
interface ApplicationValues {
  [id: string]: any;
}
export interface AdmissionQueryParams {
  email: string;
  programId: string;
  schoolId: string;
}
export interface RouterQueryParams {
  admissionFormId: string;
  id: string;
  level: string;
  userEmail?: string;
}
interface FieldOptions {
  [id: string]: SelectOptionTypes[] | { [id: string]: SelectOptionTypes[] };
}
interface ApplicationContext {
  currentTab: CurrentTab;
  currentIndex: number;
  application: any;
  setCurrentTab: (tab: CurrentTab, index: number) => void;
  changeTab: (index: number) => void;
  formJson?: FormJSON;
  updateApplication: (updatedApplication: any) => Promise<void>;
  login: (data: LoginBase) => Promise<void>;
  signUp: (data: SignUpData) => Promise<void>;
  isLast: () => boolean;
  applicationSubmitted: () => boolean;
  resetApplicationState: () => void;
  handleDegreeSelect: (degree: string) => void;
  error?: string;
  loading?: boolean;
  applicantId?: string;
  admissionFormId?: string;
  stream: string;
  fieldOptions?: FieldOptions;
  email?: string;
  schoolId?: string;
  programId?: string;
  customization?: Customization;
  initialLoginData?: LoginBase;
  isFirstLogin?: boolean;
  submittedId?: string;
  className?: string;
  selectedDegrees?: string;
  submitSuccessMessage: SubmitSuccessMessage;
  availableDegrees: AvailableDegreesType;
  applicationList?: SubmittedForm[];
  setApplicationList: (appList: SubmittedForm[]) => void;
  setApplication: (values: any) => void;
  furthestTouchedTabIndex: number;
  setFurthestTouchedTabIndex: (values: number) => void;
  formValidationErrors: any;
  setFormValidationErrors: (values: any) => void;
  isSubmitTriggered: boolean;
  setIsSubmitTriggered: (values: boolean) => void;
  currentTabFields: any;
  setCurrentTabFields: (values: any) => void;
  cognitoEmail: string;
  setCognitoEmail: (values: string) => void;
  setSelectedDegrees: (values: string) => void;
  transactionRefId: string;
  setTransactionRefId: (values: string) => void;
  openEsewaForm: any;
  setOpenEsewaForm: (values: any) => void;
}
const defaultSubmitSuccessMessage: SubmitSuccessMessage = {
  applicantId: '',
  formNo: '',
  logo: '',
  programName: '',
  admissionFormId: '',
  studentEmail: '',
  studentName: '',
};
const applicationContext = createContext<ApplicationContext>({
  currentTab: '',
  currentIndex: 0,
  stream: '',
  application: null,
  setCurrentTab: (_tab, _index) => {
    throw new Error('Not implemented');
  },
  changeTab: _index => {
    throw new Error('Not implemented');
  },
  updateApplication: _data => {
    throw new Error('Not Implemented');
  },
  login: _data => {
    throw new Error('Not Implemented');
  },
  signUp: _data => {
    throw new Error('Not Implemented');
  },
  resetApplicationState: () => {
    throw new Error('Not Implemented');
  },
  applicationSubmitted: () => false,
  isLast: () => false,
  handleDegreeSelect: _degree => {
    throw new Error('Not Implemented');
  },
  submitSuccessMessage: defaultSubmitSuccessMessage,
  availableDegrees: {},
  applicationList: [],
  setApplicationList: _data => {
    throw new Error('Not Implemented');
  },
  setApplication: _data => {
    throw new Error('Not Implemented');
  },
  furthestTouchedTabIndex: 0,
  setFurthestTouchedTabIndex: _data => {
    throw new Error('Not Implemented');
  },
  formValidationErrors: [],
  setFormValidationErrors: _data => {
    throw new Error('Not Implemented');
  },
  isSubmitTriggered: false,
  setIsSubmitTriggered: _data => {
    throw new Error('Not Implemented');
  },
  currentTabFields: {},
  setCurrentTabFields: _data => {
    throw new Error('Not Implemented');
  },
  cognitoEmail: '',
  setCognitoEmail: _data => {
    throw new Error('Not Implemented');
  },
  setSelectedDegrees: _data => {
    throw new Error('Not Implemented');
  },
  transactionRefId: '',
  setTransactionRefId: _data => {
    throw new Error('Not Implemented');
  },
  openEsewaForm: () => {},
  setOpenEsewaForm: _data => {
    throw new Error('Not Implemented');
  },
});

export const ApplicationProvider: React.FC = ({ children }) => {
  const application = useApplicationProvider();
  return <applicationContext.Provider value={application}>{children}</applicationContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useApplication = (): ApplicationContext => {
  return useContext(applicationContext);
};

export interface SubmitSuccessMessage {
  applicantId: string;
  formNo: string;
  logo: string;
  programName: string;
  admissionFormId: string;
  studentEmail: string;
  studentName: string;
}

// Provider hook that creates auth object and handles state
const useApplicationProvider = (): ApplicationContext => {
  const [currentTab, setTab] = useState<CurrentTab>('');
  const [stream, setStream] = useState<string>('');
  const [application, setApplication] = useState<ApplicationValues>({});
  const [currentIndex, setIndex] = useState<number>(0);
  const [formJson, setForm] = useState<FormJSON>();
  const [error, setError] = useState<string>();
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState<SubmitSuccessMessage>(defaultSubmitSuccessMessage);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [customization, setCustomization] = useState<Customization>();
  const [availableDegrees, setAvailableDegrees] = useState<AvailableDegreesType>({});
  const [initialLoginData, setInitialLoginData] = useState<LoginBase>({ formNo: undefined, password: '' });
  const [fieldOptions, setFieldOptions] = useState<FieldOptions>({});
  const [redirectToAdmitCard, setRedirectToAdmitCard] = useState<boolean>(false);
  const [className, setClassName] = useState<string>('');
  const [selectedDegrees, setSelectedDegrees] = useState<string>('');
  // const [tabChanged, setTabChanged] = useState(false);
  const [isSubmitTriggered, setIsSubmitTriggered] = useState<boolean>(false);
  const [applicationList, setApplicationList] = useState<SubmittedForm[]>([]);
  const [furthestTouchedTabIndex, setFurthestTouchedTabIndex] = useState<number>(0);
  const [formValidationErrors, setFormValidationErrors] = useState<any>([]);
  const [currentTabFields, setCurrentTabFields] = useState<any>({});
  const { history, push, query } = useRouter<RouterQueryParams>();
  const [idGroup, setIdGroup] = useState<AdmissionQueryParams>({
    email: '',
    programId: '',
    schoolId: '',
  });
  const [admissionFormInfo, setAdmissionFormInfo] = useState<any>({});
  const [cognitoEmail, setCognitoEmail] = useState<string>('');
  const [transactionRefId, setTransactionRefId] = useState<string>('');
  const [openEsewaForm, setOpenEsewaForm] = useState<any>(undefined);

  const { admissionFormId, id, level, userEmail } = query;
  const { email, programId, schoolId } = idGroup;

  useEffect(() => {
    if (userEmail) {
      setCognitoEmail(userEmail);
    }
  }, [userEmail]);
  useEffect(() => {
    if (!selectedDegrees && level) {
      setSelectedDegrees(level);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, setSelectedDegrees]);

  const isLast = () => {
    if (formJson) {
      return currentIndex === formJson.keys.length - 1;
    }
    return false;
  };
  const applicationSubmitted = () => {
    return application.submitted || false;
  };

  const handleDegreeSelect = (degree: string) => {
    setSelectedDegrees(degree);
  };
  const login = async (values: any) => {
    if (schoolId) {
      const [err, data] = await loginAPI({ ...values, schoolId: schoolId });
      if (err) {
        showError(err);
        return;
      }
      if (data && data.loginStatus) {
        setIdGroup({ ...idGroup, email: data.email, programId: data.programId });
        // push(`${RouteUrl.APPLY}`)
        push(`${RouteUrl.APPLICATION_LIST}`);
      } else {
        showError('Invalid Username or Password');
      }
    } else {
      showError('School Id not found');
    }
  };
  const signUp = async (values: SignUpData) => {
    if (schoolId) {
      const [err, data] = await signUpAPI({ ...values, schoolId });
      if (err) {
        showError(err);
        return;
      }
      if (data) {
        setInitialLoginData({ formNo: data.formNo, password: values.password });
        setIsFirstLogin(true);
        setStream(values.programName || '');
        push(`${RouteUrl.LOGIN}`);
      }
    }
  };

  const isAppTrinityPlusTwo = () =>
    customization?.prefix &&
    (customization.prefix === 'dev1' || customization.prefix === 'trinity') &&
    selectedDegrees === 'plusTwo'
      ? true
      : false;

  const populateInitialData = () => {
    /**
     * todo need to change BE response to for dynamic initial values
     *  {
     *   initialValues: {
     *    [name_key1]: value,
     *    [name_key2]: value,
     *    [name_key3]: value
     *   }
     *  }
     */
    if (
      isAppTrinityPlusTwo() &&
      admissionFormInfo &&
      admissionFormInfo.userEmail &&
      admissionFormInfo.userPhoneNumber &&
      admissionFormInfo.userName
    ) {
      setApplication({
        ...application,
        ...{
          pEmail: admissionFormInfo.userEmail,
          pContactNumber1: admissionFormInfo.userPhoneNumber,
          firstName: admissionFormInfo.userName || '',
        },
      });
    } else if (
      admissionFormInfo &&
      admissionFormInfo.userEmail &&
      admissionFormInfo.userPhoneNumber &&
      admissionFormInfo.firstName
    ) {
      setApplication({
        ...application,
        ...{
          pEmail: admissionFormInfo.userEmail,
          pContactNumber1: admissionFormInfo.userPhoneNumber,
          firstName: admissionFormInfo.firstName || '',
          middleName: admissionFormInfo.middleName || '',
          lastName: admissionFormInfo.lastName || '',
        },
      });
    }
  };
  useEffect(() => {
    if (admissionFormInfo) {
      populateInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admissionFormInfo]);
  useEffect(() => {
    const fetchCustomization = async () => {
      setLoading(true);
      const [err, data] = await getCustomizationAPI();
      if (err) {
        // TODO: error handling
      }
      if (data) {
        setLoading(false);
        if (data.degrees) {
          setAvailableDegrees(normalizeData(data.degrees, 'name'));
        }
        setCustomization(data);
        setIdGroup({ ...idGroup, schoolId: data.schoolId });
        if (!data.prefix) {
          setClassName('fuse-classroom');
          return;
        }
        if (data.prefix === 'try') {
          setClassName('fuse-classroom');
          return;
        }
        if (data.prefix === 'dev1') {
          setClassName('trinity');
          return;
        }
        // if (data.prefix === 'trinity') {
        //   setClassName('');
        //   return;
        // }
        setClassName(data.prefix);
      }
    };
    fetchCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getFormData = async () => {
      try {
        setLoading(true);
        let formFetchError, formJsonTemplate;
        if (userEmail?.length) {
          [formFetchError, formJsonTemplate] = await getTrinityPlusTwoAdmissionForm(admissionFormId, userEmail);
        } else {
          [formFetchError, formJsonTemplate] = await getAdmissionForm({ admissionFormId });
        }
        if (formFetchError) {
          setError(formFetchError);
        }
        if (formJsonTemplate && formJsonTemplate.templateJson) {
          const { options, ...rest } = formJsonTemplate.templateJson;
          const formObject = {
            ...rest,
          };
          setForm(formObject);
          setFieldOptions(options);
          setAdmissionFormInfo(formJsonTemplate);
        }
        setLoading(false);
      } catch (err) {
        setError('Parse Error');
      }
    };
    if (admissionFormId) {
      getFormData();
    }
    return () => {
      setForm(undefined);
    };
  }, [admissionFormId, userEmail]);
  const resetApplicationState = () => {
    setApplication({});
  };
  const defaultState = () => {
    const initialValues = generateInitialValues(formJson || undefined);
    setApplication(initialValues);
    changeTab(0);
  };
  useEffect(() => {
    const getPreviousData = async () => {
      try {
        setLoading(true);
        let previousFetchError, previousApplicationData;
        if (userEmail?.length || cognitoEmail?.length) {
          [previousFetchError, previousApplicationData] = await getTrinityPlusTwoFilledApplication({ id });
        } else {
          [previousFetchError, previousApplicationData] = await getFilledApplication({ id });
        }
        if (previousFetchError) {
          if (previousFetchError.status === 403 && id) {
            window.location.href = `${RouteUrl.APPLY}${getQueryString({ ...query, admissionFormId })}`; //? remove the applicant id  of user from the url
            return;
          }
          showError(previousFetchError.message || 'Unknown Error Occurred');
          setLoading(false);
          return;
          // setError(previousFetchError)
        }
        if (previousApplicationData) {
          setRedirectToAdmitCard(previousApplicationData.redirectToAdmitCard || false);
        }
        const initialValues = generateInitialValues(formJson || undefined);
        if (previousApplicationData && previousApplicationData.responseJson) {
          const jsonValues = previousApplicationData.responseJson;

          setApplication({ ...initialValues, ...jsonValues });
          // TODO:: refactor this
          if (formJson && jsonValues.currentIndex >= 0 && jsonValues.currentIndex < formJson.keys.length) {
            try {
              changeTab(jsonValues.currentIndex);
              setFurthestTouchedTabIndex(jsonValues.currentIndex + 1);
            } catch (err) {
              //
            }
          }
        } else {
          setApplication(initialValues);
          changeTab(0);
        }
        setLoading(false);
      } catch (err) {
        setError('Data Fetch Error');
      }
    };

    if (admissionFormId && formJson && id && !Object.keys(application).length) {
      getPreviousData();
    }
    if (admissionFormId && formJson && !id && !Object.keys(application).length) {
      defaultState();
    }
    return () => {
      if (id) {
        // only clear application if an application id is present
        // this condition prevent application clear if id is changed in first attempt.
        resetApplicationState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admissionFormId, formJson, id, userEmail]);

  const updateApplicationInitialData = (data: any) => {
    setApplication({ ...application, ...data });
  };

  const updateApplication = async (values: any) => {
    const data = values;
    if (isLast()) {
      data.submitted = values.haltSubmit ? false : true;
    }
    // ? this breaks if user manually changes the tab for now
    if (!values.currentIndex || currentIndex > values.currentIndex) {
      data.currentIndex = currentIndex;
      data.currentTab = currentTab;
    }
    let err, response;
    if (userEmail?.length) {
      [err, response] = await updateTrinityPlusTwoApplicationAPI({
        email: userEmail,
        admissionFormId,
        responseJson: data,
        id,
      });
    } else {
      [err, response] = await updateApplicationAPI({ id, admissionFormId, responseJson: data });
    }
    if (err) {
      // to reload with new templateJson data in case of error due to admin updating data while user is filling the form
      // didnt use windows.location.reload because it doesnt show the error toast msg in that case
      history.replace(`/`);
      history.replace(`${RouteUrl.APPLY}${getQueryString(query)}`);

      showError(err);
      throw err;
    }
    if (!response) {
      throw new Error('NO_BACKEND_RESPONSE');
    }
    if (response && response.responseJson) {
      const { responseJson, ...rest } = response;
      setRedirectToAdmitCard(rest.redirectToAdmitCard);
      if (!id) {
        history.replace(`${RouteUrl.APPLY}${getQueryString({ ...query, id: rest.id })}`);
      }
      updateApplicationInitialData(responseJson);
      if (data.submitted || responseJson.submitted) {
        if (redirectToAdmitCard || rest.redirectToAdmitCard) {
          push(`${RouteUrl.ADMISSION_CARD}${getQueryString({ ...query, id: rest.id })}`);
        } else {
          setSubmitSuccessMessage(rest);
          push(`${RouteUrl.APPLICATION_FORM_SUCCESS}?level=${query.level || ''}`);
        }
      }
    }
  };

  const generateInitialValues = (template?: FormJSON) => {
    let initialValues: any = {};
    try {
      if (template && template.keys && template.sections) {
        template.keys.forEach(key => {
          template.sections[key.value] &&
            template.sections[key.value].forEach(group => {
              if (!group.optional) {
                if (group.array && !group.optionalArrayChild) {
                  const requiredFieldsLength = group.requiredArrayFields || 0;
                  initialValues[group.name || ''] = requiredFieldsLength
                    ? (() => {
                        const initializedArray = [];
                        for (let i = 0; i < requiredFieldsLength; i++) {
                          initializedArray.push({});
                        }
                        return initializedArray;
                      })()
                    : [{}];
                  initialValues[group.name || ''].forEach((_: any, arrayGroupIndex: number) => {
                    group.child.forEach(child => {
                      initialValues[group.name || ''][arrayGroupIndex][child.name] =
                        (group.requiredFieldDefaults &&
                          group.requiredFieldDefaults[child.name] &&
                          group.requiredFieldDefaults[child.name][arrayGroupIndex]) ||
                        child.value ||
                        '';
                    });
                  });
                } else {
                  group.child.forEach(child => {
                    initialValues = { ...initialValues, [child.name]: child.value || '' };
                  });
                }
              }
            });
        });
      }
    } catch (err) {}
    return initialValues;
  };

  useEffect(() => {
    if (formJson) {
      setCurrentTab(formJson.keys[0].value, 0);
    }
  }, [formJson]);

  const setCurrentTab = (tab: CurrentTab, index: number) => {
    setTab(tab);
    setIndex(index);
  };
  const changeTab = (index: number) => {
    if (index < 0) {
      throw new Error('Invalid operation');
    }
    if (formJson && formJson.keys.length >= index) {
      setIndex(index);
      setTab(formJson.keys[index].value);
    } else {
      throw new Error('LAST');
    }
  };

  // useEffect(() => {
  //   if (formJson && currentIndex !== formJson.keys.length - 1) {
  //     // sets the flag in order to prevent infinite reload and to reload on last page only if its due to tab change
  //     setTabChanged(true);
  //   }
  // to fetch exam schedule data on tab change since admin might be updating info while the user is filling application form
  // if (application && !application.submitted && formJson && tabChanged && currentIndex === formJson.keys.length - 1) {
  // window.location.reload();
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentIndex]);

  // Return the user object and auth methods
  return {
    currentTab,
    currentIndex,
    application,
    formJson,
    error,
    loading,
    applicantId: '',
    admissionFormId,
    email,
    schoolId,
    programId,
    customization,
    stream,
    initialLoginData,
    isFirstLogin,
    submittedId: id,
    fieldOptions,
    className,
    selectedDegrees,
    submitSuccessMessage,
    availableDegrees,
    login,
    signUp,
    setCurrentTab,
    resetApplicationState,
    updateApplication,
    changeTab,
    isLast,
    applicationSubmitted,
    handleDegreeSelect,
    applicationList,
    setApplicationList,
    setApplication,
    furthestTouchedTabIndex,
    setFurthestTouchedTabIndex,
    formValidationErrors,
    setFormValidationErrors,
    isSubmitTriggered,
    setIsSubmitTriggered,
    currentTabFields,
    setCurrentTabFields,
    cognitoEmail,
    setCognitoEmail,
    setSelectedDegrees,
    transactionRefId,
    setTransactionRefId,
    openEsewaForm,
    setOpenEsewaForm,
  };
};
